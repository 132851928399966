import { CancelToken } from 'apisauce';
import shortid from 'shortid';
import _ from 'lodash';

import appConfig from 'src/appConfig';
import { isEmpty } from 'src/validations';
import { BehaviorCategoryType, BehaviorMeasureType, PracticeElementType, sessionOptionsSelectSLType, TargetAreaType } from 'src/redux/sessionLogsRedux/types';
import { Target } from 'src/redux/tieredServices/types';

export function newCancelToken(timeout = appConfig.CONNECTION_TIMEOUT) {
  const source = CancelToken.source();
  setTimeout(() => {
    source.cancel();
  }, timeout);

  return { cancelToken: source.token };
}

export const getRandomId = (): string => shortid.generate();

export const stringify = (params: { [key: string]: number | string | string[] }) => {
  let result = '';

  Object.keys(params).forEach(key => {
    if (!isEmpty(params[key])) {
      if (Array.isArray(params[key])) {
        let array = params[key] as string[];
        array.forEach((param: string) => {
          result += `&${key}=${param}`;
        });
      } else {
        result += `&${key}=${params[key]}`;
      }
    }
  });

  result = result.replace(/^&/, '');

  return result;
};

export const emptyFunction = () => {};

export const titleCase = (text: string) => {
  return _.startCase(_.camelCase(text));
};

export const filterOptionsMatchAllKeywords =
  <T,>(getOptionTitle: (o: T) => string) =>
  (options: T[], { inputValue }: { inputValue: string }) => {
    const keywords = inputValue.toLowerCase().split(' ');

    const match = (option: T, keys: string[]) =>
      keys.filter(k => getOptionTitle(option).toLowerCase().indexOf(k) !== -1).length === keys.length;

    const matches = options.filter(o => match(o, keywords));
    return matches;
  };

  export const filterDisabledOptions =
  (options: TargetAreaType[]) => {
    let newOptions = [];
    options.forEach((element) => {
      if (!element.disabledFlag) {
        newOptions.push(element);
      }
    });
    return newOptions;
  };

  export const filterDisabledPMOptions =
  (options: BehaviorCategoryType[]) => {
    let newOptions = [];
    options.forEach((element) => {
      if (!element.deletedFlag) {
        newOptions.push(element);
      }
    });
    return newOptions;
  };

  export const filterDisabledBMOptions =
  (options: BehaviorMeasureType[], pmValue: string) => {
    let newOptions = [];
    options.forEach((element) => {
      if (!element.deletedFlag && pmValue === element.measureCategoryCode) {
        newOptions.push(element);
      }
    });
    return newOptions;
  };

  export const filterDisabledPracticeOptions =
  (options: PracticeElementType[]) => {
    let newOptions = [];
    options.forEach((element) => {
      if (!element.disabledFlag) {
        newOptions.push(element);
      }
    });
    return newOptions;
  };

  export const filterDisabledOptionsTS =
  (options: Target[]) => {
    let newOptions = [];
    options.forEach((element) => {
      if (!element.disabledFlag) {
        newOptions.push(element);
      }
    });
    return newOptions;
  };

  export const filterDisabledPMOCOptions =
  (options: sessionOptionsSelectSLType[]) => {
    let newOptions = [];
    options.forEach((element) => {
      if (!element.disabledFlag) {
        newOptions.push(element);
      }
    });
    return newOptions;
  };
