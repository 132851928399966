import React from 'react';
import PageContentContainer from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import './styles.scss';
/* eslint-disable */
const Version: React.VoidFunctionComponent = ({
}) => {

  return (
    <PageContentContainer>
      <StickyPageHeader>
        <br/>
        <h3>Branch: uat/1.6.1-RC1</h3><br />
        <h3>Commit: 5af7a818</h3><br />
        <h3>Build Date: 2023-10-12T02:14:25Z</h3>
      </StickyPageHeader>
    </PageContentContainer>
  );
};

export default (Version);